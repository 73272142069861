import React, { createContext, useContext, useMemo, useState } from 'react'
import useQuiz from '../hooks/useQuiz'
import useResize from '../hooks/useResize'
import { breakpointsPx } from '../style/theme'
import { questions, mapping } from '../data/data.json'
import PropTypes from 'prop-types'
import useMedia from 'use-media'

export const AppContext = createContext(null)

const AppProvider = ({ children }) => {
  const [loaded, setLoaded] = useState(false)
  const [folded, setFolded] = useState(false)

  const data = {
    list: questions,
    boards: mapping,
    ready: true, // todo: if we're not fetching data, we can remove this globally
  }
  const {
    answers,
    boardResults,
    clearStoredAnswers,
    clearBoardResults,
    foundNothing,
    price,
    questionId,
    setAnswer,
    setFoundNothing,
    setQuestionId,
    setResult,
    values,
  } = useQuiz(data)

  const size = useResize()

  const isSmall = useMedia(`(max-width: ${breakpointsPx.md - 1}px)`)
  const isMedium = useMedia(`(min-width: ${breakpointsPx.md}px)`)
  const isLarge = useMedia(`(min-width: ${breakpointsPx.lg}px)`)
  const isXLarge = useMedia(`(min-width: ${breakpointsPx.xl}px)`)
  const isXXLarge = useMedia(`(min-width: ${breakpointsPx.xxl}px)`)

  const value = useMemo(() => {
    return {
      loaded,
      setLoaded,
      data: data.list,
      dataReady: data.ready,
      media: {
        isMedium,
        isLarge,
        isSmall,
        isXLarge,
        isXXLarge,
      },
      quiz: {
        answers,
        boardResults,
        foundNothing,
        setAnswer,
        clearBoardResults,
        clearStoredAnswers,
        questionId,
        setFoundNothing,
        setQuestionId,
        setResult,
        price,
        values,
      },
      size,
      folded,
      setFolded,
    }
  }, [
    folded,
    foundNothing,
    setFolded,
    loaded,
    isMedium,
    isSmall,
    isLarge,
    isXLarge,
    isXXLarge,
    answers,
    setAnswer,
    clearBoardResults,
    clearStoredAnswers,
    price,
    questionId,
    boardResults,
    setResult,
    setFoundNothing,
    setQuestionId,
    size,
    data,
    values,
  ])

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>
}

AppProvider.propTypes = {
  children: PropTypes.any,
}

export const useAppContext = () => useContext(AppContext)

export default AppProvider
