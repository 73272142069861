import { createGlobalStyle } from 'styled-components/macro'
import fonts from './fonts'
import { HELVETICA_FOR_TARGET } from '../constants/type'
import theme from './theme'

const GlobalStyle = createGlobalStyle`
  ${fonts()};
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }
  
  html {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
  }
  body {
    line-height: 1;
    margin: 0;
    padding: 0;
    font-family: ${HELVETICA_FOR_TARGET};
    height: 100%;
    color: ${theme.color.questionText};
    background-color: ${theme.color.background};
  }
  ol, ul {
    list-style: none;
  }
  blockquote, q {
    quotes: none;
  }
  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  * {
    box-sizing: border-box;
  }

  *:focus {
    outline: none!important;
  }
  
  a, a:hover, a:visited {
    color: inherit;
    text-decoration: none;
  }
  
  #root {
    width: 100%;
    height: 100%;
    > div {
      width: 100%;
      height: 100%;
    }
  }
  
`

export default GlobalStyle
