import {
  CIRCULAR_STD_BOLD,
  HELVETICA_FOR_TARGET,
  HELVETICA_FOR_TARGET_BOLD,
  HELVETICA_FOR_TARGET_MEDIUM,
  HELVETICA_FOR_TARGET_ITALIC,
} from '../constants/type'

import { css } from 'styled-components/macro'

import CircularStdBoldEot from '../fonts/CircularStd-Bold.eot'
import CircularStdBoldTtf from '../fonts/CircularStd-Bold.ttf'
import CircularStdBoldWoff from '../fonts/CircularStd-Bold.woff'
import CircularStdBoldWoff2 from '../fonts/CircularStd-Bold.woff2'

import HelveticaForTargetEot from '../fonts/HelveticaforTarget.eot'
import HelveticaForTargetTtf from '../fonts/HelveticaforTarget.ttf'
import HelveticaForTargetWoff from '../fonts/HelveticaforTarget.woff'
import HelveticaForTargetWoff2 from '../fonts/HelveticaforTarget.woff2'

import HelveticaForTargetBoldEot from '../fonts/HelveticaforTarget-Bold.eot'
import HelveticaForTargetBoldTtf from '../fonts/HelveticaforTarget-Bold.ttf'
import HelveticaForTargetBoldWoff from '../fonts/HelveticaforTarget-Bold.woff'
import HelveticaForTargetBoldWoff2 from '../fonts/HelveticaforTarget-Bold.woff2'

import HelveticaForTargetMediumEot from '../fonts/HelveticaforTarget-Medium.eot'
import HelveticaForTargetMediumTtf from '../fonts/HelveticaforTarget-Medium.ttf'
import HelveticaForTargetMediumWoff from '../fonts/HelveticaforTarget-Medium.woff'
import HelveticaForTargetMediumWoff2 from '../fonts/HelveticaforTarget-Medium.woff2'

import HelveticaForTargetRmPlItEot from '../fonts/HelveticaforTarget-RmPlIt.eot'
import HelveticaForTargetRmPlItTtf from '../fonts/HelveticaforTarget-RmPlIt.ttf'
import HelveticaForTargetRmPlItWoff from '../fonts/HelveticaforTarget-RmPlIt.woff'
import HelveticaForTargetRmPlItWoff2 from '../fonts/HelveticaforTarget-RmPlIt.woff2'

export default () => css`

  @font-face {
    font-family: ${CIRCULAR_STD_BOLD};
    src: url('${CircularStdBoldEot}');
    src: url('${CircularStdBoldEot}#iefix') format('embedded-opentype'),
      url('${CircularStdBoldWoff}') format('woff'),
      url('${CircularStdBoldWoff2}') format('woff2'),
      url('${CircularStdBoldTtf}')  format('truetype');
    font-style: normal;
    font-weight: normal;
  }

  @font-face {
    font-family: ${HELVETICA_FOR_TARGET};
    src: url('${HelveticaForTargetEot}');
    src: url('${HelveticaForTargetEot}#iefix') format('embedded-opentype'),
       url('${HelveticaForTargetWoff}') format('woff'),
       url('${HelveticaForTargetWoff2}') format('woff2'),
       url('${HelveticaForTargetTtf}')  format('truetype');
    font-style: normal;
    font-weight: normal;
  }

  @font-face {
    font-family: ${HELVETICA_FOR_TARGET_BOLD};
    src: url('${HelveticaForTargetBoldEot}');
    src: url('${HelveticaForTargetBoldEot}#iefix') format('embedded-opentype'),
       url('${HelveticaForTargetBoldWoff}') format('woff'),
       url('${HelveticaForTargetBoldWoff2}') format('woff2'),
       url('${HelveticaForTargetBoldTtf}')  format('truetype');
    font-style: normal;
    font-weight: normal;
  }

  @font-face {
    font-family: ${HELVETICA_FOR_TARGET_MEDIUM};
    src: url('${HelveticaForTargetMediumEot}');
    src: url('${HelveticaForTargetMediumEot}#iefix') format('embedded-opentype'),
       url('${HelveticaForTargetMediumWoff}') format('woff'),
       url('${HelveticaForTargetMediumWoff2}') format('woff2'),
       url('${HelveticaForTargetMediumTtf}')  format('truetype');
    font-style: normal;
    font-weight: normal;
  }

  @font-face {
    font-family: ${HELVETICA_FOR_TARGET_ITALIC};
    src: url('${HelveticaForTargetRmPlItEot}');
    src: url('${HelveticaForTargetRmPlItEot}#iefix') format('embedded-opentype'),
       url('${HelveticaForTargetRmPlItWoff}') format('woff'),
       url('${HelveticaForTargetRmPlItWoff2}') format('woff2'),
       url('${HelveticaForTargetRmPlItTtf}')  format('truetype');
    font-style: normal;
    font-weight: normal;
  }
`
