import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import ProcessingBoard from './views/ProcessingBoard'
import Section from '../../common/Section'
import Container from '../../common/Container'
import Content from '../../common/Content'
import styled, { css } from 'styled-components/macro'
import PreviousLink from '../../common/PreviousLink'
import Text from '../../common/Text'
import { AnimatePresence } from 'framer-motion'
import { useAppContext } from '../../../context/AppContext'
import { navigate } from '@reach/router'
import { ROUTES } from '../../../constants'
import { QuestionTransitions } from '../../../style/transitions'
import { BackArrow } from '../../common/Arrow'
import { ReactComponent as RetakeIcon } from '../../../assets/redo.svg'
import theme, { fontSizes } from '../../../style/theme'
import { useAnalytics } from 'use-analytics'
import Background from '../../common/Background'
import {
  CIRCULAR_STD_BOLD,
  HELVETICA_FOR_TARGET,
  HELVETICA_FOR_TARGET_BOLD,
} from '../../../constants/type'

const waitAndExecute = (ms, fn) =>
  new Promise(r =>
    setTimeout(() => {
      fn()
      r()
    }, ms),
  )

let tempProdImgs = []

const Product = ({ brand, product, url, imagename, click }) => {
  tempProdImgs = [...tempProdImgs.slice(1), tempProdImgs[0]]
  const imageUrl = `/images/products/${imagename}`

  return (
    <ProductRow>
      <ProdImage>
        <ProductImageInner image={imageUrl} />
      </ProdImage>
      <ProductDesc>
        <ProductInner>
          <Brand>{brand}</Brand>
          {product}
          <ProductButton
            small
            center
            color={theme.color.questionText}
            href={url}
            text={theme.color.nextQuestionButton}
            target="_blank"
            onClick={click}
          >
            Shop Now
          </ProductButton>
        </ProductInner>
      </ProductDesc>
    </ProductRow>
  )
}
Product.propTypes = {
  brand: PropTypes.string,
  click: PropTypes.func,
  product: PropTypes.string,
  url: PropTypes.string,
  imagename: PropTypes.string,
}

const BackButton = ({ onClick }) => {
  return (
    <AnimatePresence exitBeforeEnter>
      <PreviousLink
        key="prevLink"
        onClick={onClick}
        initial={QuestionTransitions.initial}
        animate={QuestionTransitions.animate}
        exit={QuestionTransitions.exit}
        transition={QuestionTransitions.transition(0.5, 0.2)}
        results="true"
      >
        <BackArrow />
      </PreviousLink>
    </AnimatePresence>
  )
}
BackButton.propTypes = {
  onClick: PropTypes.func,
}

const Results = () => {
  const {
    quiz: {
      answers,
      boardResults,
      clearBoardResults,
      foundNothing,
      price,
      setFoundNothing,
      setResult,
      values,
    },
    size,
    wrapped,
  } = useAppContext()
  const { track } = useAnalytics()

  const { pc } = fontSizes
  const [waiting, setWaiting] = useState(true)

  const trackName = name => {
    return `outbound_link:${name
      .replace(/\s+/g, '_')
      .replace(/[^a-z0-9+-_]+/gi, '')
      .toLowerCase()}`
  }

  useEffect(() => {
    if (!!values?.length && values.length === 3) {
      track(`results:${values.join('_')}`)
    }
  }, [values])

  useEffect(() => {
    clearBoardResults()
  }, [clearBoardResults])

  useEffect(() => {
    if (foundNothing) {
      setFoundNothing(false)
      navigate(ROUTES.HOME)
    }
    if (boardResults) {
      // todo: change to 5000
      waitAndExecute(2000, () => {
        // todo: clear stored answers and show responses
        setWaiting(false)
      })
    } else {
      setResult(answers)
    }
  }, [answers, boardResults, foundNothing, setResult, setFoundNothing])

  const handlePrevQuestion = () => {
    navigate(ROUTES.HOME)
  }

  return (
    <Section width={size && size.width}>
      <Background />
      <Container>
        {!wrapped &&
          (waiting ? (
            <Content process>
              <ProcessingBoard />
            </Content>
          ) : (
            <Content records>
              {boardResults && boardResults.length ? (
                <>
                  <Wrap>
                    <AnimatePresence exitBeforeEnter key={2}>
                      <Text
                        size={38}
                        xs={38}
                        sm={38}
                        lg={49}
                        xl={50}
                        xxl={pc.title}
                        center
                        font={HELVETICA_FOR_TARGET_BOLD}
                        lineHeightMultiplier={1.1}
                      >
                        Gifts under ${price}!
                      </Text>
                    </AnimatePresence>
                    <div>
                      <Description
                        size={17}
                        lg={30}
                        xl={33}
                        center
                        font={HELVETICA_FOR_TARGET}
                        lineHeight="1em"
                      >
                        What we value most, shouldn’t cost more.
                      </Description>
                    </div>
                  </Wrap>
                  <Scrollable>
                    <AnimatePresence exitBeforeEnter key={3}>
                      <ProductWrapper key={5}>
                        {boardResults.map(obj => (
                          <Product
                            sku={obj.id}
                            brand={obj.title}
                            product=""
                            url={obj.url}
                            key={obj.id}
                            price={obj.price}
                            imagename={obj.imagename}
                            click={() => track(trackName(obj.title))}
                          />
                        ))}
                        <RetakeWrap>
                          <a
                            href="/"
                            onClick={() => track('retake_quiz_click', {})}
                          >
                            <Retake>
                              <div>
                                <RetakeIcon />
                              </div>
                              <RetakeText>Retake Quiz</RetakeText>
                            </Retake>
                          </a>
                        </RetakeWrap>
                      </ProductWrapper>
                    </AnimatePresence>
                  </Scrollable>
                  <BackButton onClick={handlePrevQuestion} />
                  <MoreWrapper>
                    <MoreButton
                      target="_blank"
                      href="https://www.target.com/c/gift-ideas/-/N-96d2i"
                      onClick={() => track('explore_more_click', {})}
                    >
                      Explore More
                    </MoreButton>
                  </MoreWrapper>
                </>
              ) : (
                <>
                  <>
                    <Wrap>
                      <AnimatePresence exitBeforeEnter key={2}>
                        <div>
                          <Lead key={1}>
                            <Text
                              size={28}
                              lg={49}
                              xl={pc.title}
                              center
                              lineHeight="1em"
                              font={HELVETICA_FOR_TARGET_BOLD}
                              lineHeightMultiplier={0.8}
                            >
                              Results could not be found.
                            </Text>
                          </Lead>
                          <div key={2}>
                            <Description
                              size={20}
                              xl={33}
                              center
                              font={HELVETICA_FOR_TARGET}
                              lineHeightMultiplier={1}
                              lineHeight="1em"
                            >
                              Try answering the questions again.
                            </Description>
                          </div>
                        </div>
                      </AnimatePresence>
                      <BackButton onClick={handlePrevQuestion} />
                    </Wrap>
                  </>
                </>
              )}
            </Content>
          ))}
      </Container>
    </Section>
  )
}

Results.propTypes = {}

export default Results

const Scrollable = styled.div`
  ${({ theme }) => css`
    width: 100%;
    max-height: 100%;
    position: relative;
    margin-top: 2em;
    ${theme.media.xl`
      padding-top: 0;
      margin: 0 auto 0;
      width: 75%;
    `}
  `}
`
const ProductWrapper = styled.div`
  ${({ theme }) => css`
    position: relative;
    width: 100%;
    margin: 0 auto;
    padding: 0 0 6em;
    font-family: ${CIRCULAR_STD_BOLD};

    ${theme.media.lg`
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      padding-right: 1em;
    `}
  `}
`
const ProdImage = styled.div`
  ${({ theme }) => css`
    border-radius: 100%;
    padding-bottom: calc(50% - 0.5em);
    overflow: hidden;
    margin-right: 1em;
    width: 100%;
    min-width: 50%;
    position: relative;
    background: ${theme.color.white};
    border: solid 4px white;

    ${theme.media.lg`
      margin-right: 1em;
      width: calc(100% - 2em);
      margin-left: 1.6em;
      max-width: 35%;
    `}
  `}
`
const ProductImageInner = styled.div`
  ${({ image }) => css`
    background: url(${image}) white;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    min-width: 100%;
    min-height: 100%;
    position: absolute;
  `}
`
const Description = styled(Text)`
  ${({ theme }) => css`
    margin: 1em auto;

    ${theme.media.lg`
      margin: 1em auto 1em;
    `}
  `}
`
const Wrap = styled.div`
  position: relative;
`
const ProductRow = styled.div`
  ${({ theme }) => css`
    display: flex;
    max-width: 90%;
    margin: 0 auto 1.5em;

    ${theme.media.lg`
      flex: 50%;
      margin: 0px auto 1.5em 0;
      max-width: 50%;
    `}
  `}
`
const ProductDesc = styled.div`
  ${() => css`
    color: black;
    font-size: 0.8125em;
    font-family: ${CIRCULAR_STD_BOLD};
    position: relative;
    width: 100%;
  `}
`
const ProductButton = styled.a`
  ${({ theme }) => css`
    background-color: ${theme.color.mainFont};
    border: solid 2px #9a1718;
    font-family: ${HELVETICA_FOR_TARGET_BOLD};
    border-radius: 50px;
    padding: 0.2em 0.8em;
    margin: 0.5em 0 0;
    color: ${theme.color.questionText};
    display: inline-block;
    text-align: center;
    font-size: 1em;
    text-shadow: none;

    &:visited,
    &:hover,
    &:active {
      color: ${theme.color.questionText};
    }

    ${theme.media.lg`  
      font-size: 1.25em;
      border: solid 4px #9a1718;
    `}
  `}
`
const Brand = styled.div`
  ${({ theme }) => css`
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.08px;
    ${theme.media.xl`
    font-size: 19px;
    line-height: 21px;
  `}
    ${theme.media.xxl`
    font-size: 22px;
    line-height: 24px;
  `}
  `}
`
const ProductInner = styled.div`
  ${({ theme }) => css`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: ${theme.color.questionText};
    font-size: 15px;
    line-height: 24px;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);

    ${theme.media.lg`
      font-size: 1em;
      line-height: 24px;
      padding: 0 0 0;
    `}

    ${theme.media.xl`
      font-size: 1.2em;
    line-height: 24px;
      padding: 0 0 0;
    `}
  `}
`
const MoreWrapper = styled.div`
  ${({ theme }) => css`
    left: 0;
    bottom: 0;
    position: fixed;
    z-index: 1;
    width: 100%;

    ${theme.media.lg`
      bottom: 3%;
    `}
  `}
`
const MoreButton = styled.a`
  ${({ theme }) => css`
    font-family: ${CIRCULAR_STD_BOLD};
    left: 50%;
    color: #a40035;
    transition: opacity 0.3s;
    cursor: pointer;
    transform: translateX(-50%);
    max-width: 37%;
    border: 0;
    box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 50px;
    background-color: #ffe1d6;
    position: relative;
    font-size: 1em;
    padding: 0.5em;
    margin-bottom: 1em;
    display: block;
    text-align: center;

    &:visited,
    &:hover,
    &:active {
      color: ${theme.color.mainFont};
    }

    ${theme.media.lg`
      width: 18%;
      max-width: 300px;
      font-size: 1.2em;
      box-shadow: 0px 3px 20px 0px rgba(0,0,0,0.39);
    `}
  `}
`
const Lead = styled(Text)`
  ${({ theme }) => css`
    margin: 6% auto;

    ${theme.media.lg`
      margin: 0 auto;
    `}
  `}
`
const RetakeWrap = styled.div`
  ${({ theme }) => css`
    position: relative;
    padding: 1em 0 4em;
    text-align: center;
    color: #dbbfb0;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
    justify-content: center;
    width: 100%;
    ${theme.media.lg`
      padding: 3em 0 4em;
    `}
  `}
`
const Retake = styled.div`
  display: inline-flex;
  cursor: pointer;
  align-items: center;
`
const RetakeText = styled.div`
  padding-left: 0.5em;
`
