import React, { useState, useEffect } from 'react'
import styled from 'styled-components/macro'

const countByAndExecute = (ms, fn) => {
  return setInterval(() => {
    fn()
  }, ms)
}

const Ellipses = () => {
  const dots = [1, 2, 3]
  const [ellipsisCount, setEllipsisCount] = useState(3)

  useEffect(() => {
    let count = 0
    const interval = countByAndExecute(250, () => {
      setEllipsisCount(count++ % 4)
    })

    return () => clearInterval(interval)
  }, [])

  return (
    <>
      {dots.map((i, key) => (
        <Dot
          key={key}
          style={{
            opacity: i <= ellipsisCount ? 1 : 0,
          }}
        >
          .
        </Dot>
      ))}
    </>
  )
}

export default Ellipses

const Dot = styled.span`
  transition: opacity 0.2s;
`
