import React from 'react'
import ReactDOM from 'react-dom'
import App from './components/containers/App'
import AppProvider from './context/AppContext'

const render = Component => {
  return ReactDOM.render(
    <AppProvider>
      <Component />
    </AppProvider>,
    document.getElementById('root'),
  )
}

render(App)

if (module.hot) {
  module.hot.accept('./components/containers/App', () => {
    const NextApp = require('./components/containers/App').default
    render(NextApp)
  })
}
