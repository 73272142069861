import useLocalStorage from './useLocalStorage'
import { useEffect, useState } from 'react'

const getSerializedAnswers = (data, answersSerialized) => {
  try {
    return JSON.parse(answersSerialized)
  } catch (err) {
    return data.map(() => [])
  }
}

function useAnswerStorage(data) {
  const [answersSerialized, setAnswersSerialized] = useLocalStorage('answers')
  const [storedAnswers, setStoredAnswers] = useState(
    getSerializedAnswers(data, answersSerialized),
  )

  useEffect(() => {
    setStoredAnswers(getSerializedAnswers(data, answersSerialized))
    //eslint-disable-next-line
  }, [answersSerialized])

  const serializeAnswers = answers => {
    try {
      if (storedAnswers) setAnswersSerialized(JSON.stringify(answers))
      else setAnswersSerialized(null)
    } catch (err) {
      console.error('Error serializing answers: ', err)
    }
  }

  return { storedAnswers, serializeAnswers }
}

export default useAnswerStorage
