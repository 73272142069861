import React, { useCallback, useEffect, useRef, useState } from 'react'
import Container from '../../common/Container'
import Section from '../../common/Section'
import styled from 'styled-components/macro'
import Button from '../../common/Button'
import Answers from '../../common/Answers'
import { ROUTES, TYPE_ANSWER_CLICK } from '../../../constants'
import { navigate } from '@reach/router'
import { useAppContext } from '../../../context/AppContext'
import { AnimatePresence, motion } from 'framer-motion'
import { QuestionTransitions } from '../../../style/transitions'
import { BackArrow } from '../../common/Arrow'
import theme, { fontSizes } from '../../../style/theme'
import Content from '../../common/Content'
import PrevLink from '../../common/PreviousLink'
import Text from '../../common/Text'
import { css } from 'styled-components'
import { useAnalytics } from 'use-analytics'
import SmallBreak from '../../common/SmallBreak'
import { rem } from 'polished'
import Background from '../../common/Background'
import {
  HELVETICA_FOR_TARGET_BOLD,
  HELVETICA_FOR_TARGET_ITALIC,
} from '../../../constants/type'

const Questions = () => {
  const {
    data,
    media: { isLarge },
    quiz: { answers, setAnswer, questionId, setQuestionId },
    size,
  } = useAppContext()
  const { track, page } = useAnalytics()

  const { pc } = fontSizes

  const [leadHeight, setLeadHeight] = useState()
  const [isFlex, setIsFlex] = useState(true)
  const [isAnimating, setIsAnimating] = useState(false)
  const [galleryTransitionComplete, setGalleryTransitionComplete] = useState(
    true,
  )
  useEffect(() => {
    page()
    //eslint-disable-next-line
  }, [questionId])

  const questionIndex = questionId - 1
  const currentQuestion = data[questionIndex]
  const atLastQuestion = questionId >= data.length

  const setColumns = value => {
    setGalleryTransitionComplete(value)
    setIsFlex(currentQuestion.answers.length > 4 || isLarge)
  }

  const isDisabled =
    answers[questionIndex].length < 1 || !galleryTransitionComplete

  function useHookWithRefCallback() {
    const ref = useRef(null)
    const setRef = useCallback(node => {
      if (ref.current) {
      }

      if (node) {
        setLeadHeight(node.getBoundingClientRect().height)
      }

      // Save a reference to the node
      ref.current = node
    }, [])

    return [setRef]
  }
  const [ref] = useHookWithRefCallback()

  useEffect(() => {
    track('page_load')
  }, [track])

  const handleNextQuestion = useCallback(() => {
    // GA track
    if (!isDisabled && answers[questionIndex].length > 0) {
      const question = `Q${questionIndex + 1}`

      if (atLastQuestion) {
        track(`${question}_finished`, {})
      } else {
        track(`${question}_answered`, {})
      }
    }
    if (!isDisabled && !atLastQuestion) {
      toggleAnimation()
      setQuestionId(questionId + 1)
    } else if (atLastQuestion) {
      navigate(ROUTES.RESULTS)
    }
    //eslint-disable-next-line
  }, [atLastQuestion, isDisabled, questionId, setQuestionId])

  const handlePrevQuestion = useCallback(() => {
    if (questionIndex === 0) {
      navigate(ROUTES.HOME)
    } else if (questionId <= data.length) {
      toggleAnimation()
      setQuestionId(questionId - 1)
    }
    //eslint-disable-next-line
  }, [questionId, setQuestionId])

  const handleAnswerSelect = useCallback(
    answerIndex => () =>
      setAnswer({
        type: TYPE_ANSWER_CLICK,
        questionIndex,
        answerIndex,
        maxAnswerIndex: currentQuestion.maxAnswers,
        data,
      }),
    //eslint-disable-next-line
    [currentQuestion.maxAnswers, questionIndex, setAnswer],
  )

  const toggleAnimation = () => {
    setIsAnimating(true)
    setTimeout(() => setIsAnimating(false), 500)
  }
  return (
    <Section width={size && size.width}>
      <Container>
        <Background />
        <Content>
          {questionIndex > 0 && !isAnimating && (
            <AnimatePresence exitBeforeEnter>
              <PrevLink
                key="prevLink"
                onClick={handlePrevQuestion}
                initial={QuestionTransitions.initial}
                animate={QuestionTransitions.animate}
                exit={QuestionTransitions.exit}
                transition={QuestionTransitions.transition(0.3, 0.2)}
              >
                <BackArrow />
              </PrevLink>
            </AnimatePresence>
          )}
          {questionIndex === 0 && (
            <AnimatePresence exitBeforeEnter>
              <Instructions ref={ref} height={leadHeight}>
                Answer 3 easy questions <SmallBreak />
                for thoughtful gift ideas.
              </Instructions>
            </AnimatePresence>
          )}
          <Question>
            {!isAnimating &&
              data
                .filter((q, i) => i === questionIndex)
                .map((question, i) => {
                  return (
                    <AnimatePresence exitBeforeEnter key={`ap${i}`}>
                      <motion.div
                        initial={QuestionTransitions.initial}
                        animate={QuestionTransitions.animate}
                        exit={QuestionTransitions.exit}
                        transition={QuestionTransitions.transition(0.5, 0.35)}
                        key={`q${i}`}
                      >
                        <QuestionText
                          size={38}
                          xs={35}
                          sm={35}
                          lg={45}
                          center
                          xl={pc.title}
                          font={HELVETICA_FOR_TARGET_BOLD}
                          lineHeightMultiplier={1.1}
                          key={i}
                        >
                          {question.title}
                        </QuestionText>
                      </motion.div>
                    </AnimatePresence>
                  )
                })}
          </Question>
          <Answers
            answers={answers}
            currentQuestion={currentQuestion}
            questionIndex={questionIndex}
            handleAnswerSelect={handleAnswerSelect}
            handleTransitionComplete={setColumns}
            isFlex={isFlex}
          />

          <AnimatePresence exitBeforeEnter>
            <ButtonContainer
              key={`btn${questionIndex}`}
              initial={QuestionTransitions.initial}
              animate={QuestionTransitions.animate}
              exit={QuestionTransitions.exit}
              transition={QuestionTransitions.transition(
                0.3,
                (answers.length - 1) * 0.05,
              )}
            >
              <Button
                onClick={handleNextQuestion}
                disabled={isDisabled || isAnimating}
                small
                center
                text={theme.color.mainFont}
                color={theme.color.nextQuestionButton}
              >
                {atLastQuestion ? <>Finish</> : <>Next</>}
              </Button>
            </ButtonContainer>
          </AnimatePresence>
        </Content>
      </Container>
    </Section>
  )
}

Questions.propTypes = {}

export default Questions

const QuestionText = styled(Text)`
  ${({ theme }) => css`
    padding: 0 0.7em;

    ${theme.media.lg`
    `};
  `}
`
const Question = styled.div`
  ${({ theme }) => css`
    padding-bottom: 2em;

    ${theme.media.sm`
    `}

    ${theme.media.lg`
      margin: 0;
      padding: 0;
      transform: initial;
      top: auto;
    `};
  `}
`
const ButtonContainer = styled(motion.div)`
  ${({ theme }) => css`
    ${theme.media.xl`
      opacity: 1;
      transform: none;
      max-width: 60%;
      position: relative;
      margin: 0 auto;
      font-size: 1rem;
    `}
  `}
`
const Instructions = styled(motion.div)`
  ${({ theme, height }) => css`
    font-size: ${rem(14)};
    text-align: center;
    z-index: 1000;
    width: auto;
    left: 0;
    pointer-events: none;
    right: 0;
    padding-bottom: 1.5em;
    
    ${height &&
      css`
        margin-top: ${`-${height}px`};
      `}

    ${theme.media.lg`
      font-size: ${rem(18)};
      /*font-family: ${HELVETICA_FOR_TARGET_ITALIC};*/
      position: relative;
      padding-bottom: 0.5em;
    `}

    ${theme.media.xl`
      font-size: ${rem(21)};
    `}
  `}
`
