import React from 'react'
import bg from '../../assets/images/background/bg.svg'
import mobile from '../../assets/images/background/mobile/bg.svg'
import styled, { css } from 'styled-components'

const Background = () => {
  return (
    <Container>
      <Image src={bg} mobile={mobile} />
    </Container>
  )
}
export default Background
const Container = styled.div`
  background-color: #800613;
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  right: 0;
`
const Image = styled.div`
  ${({ src, mobile, theme }) => css`
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    right: 0;
    background: url(${mobile}) no-repeat;
    ${theme.media.lg`
      background-image: url(${bg});
    `}
    background-size: cover;
    background-position: right bottom;
  `}
`
