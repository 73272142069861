import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'

const Section = styled(motion.section)`
  ${({ theme, bg, width }) => css`
    position: relative;
    overflow: hidden;
    z-index: 1;
    width: 100%;
    min-height: 100%;
    /*max-width: 1265px;*/
    margin: 0 auto;

    ${theme.media.lg`
    `}
  `}
`

Section.defaultProps = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
  transition: { duration: 0.15 },
}

export default Section
