import styled, { css } from 'styled-components'
const Content = styled.div(
  ({ theme, process, records }) => css`
    width: 100%;
    background-repeat: no-repeat;
    background-position: top center;
    background-size: contain;
    padding: 0 6%;
    padding-top: 70px;
    position: relative;

    ${theme.media.lg`
    padding-top: 140px;
      width: 100%;
      overflow: visible;
      height: auto;
      bottom: auto;   
      
      ${process &&
        `
        bottom: auto;
        top: 0;
        overflow: visible;
        position: absolute;
        height: 100%;
        transform: none;
      `}   
      
      ${records &&
        `
        bottom: auto;
        top: 0;
        overflow: hidden;
        height: 100%;
        transform: none;
      `}
    `};
  `,
)
export default Content
