const { REACT_APP_QUESTION_IMAGE_EXT } = process.env

export const QUESTION_IMAGE_EXT = REACT_APP_QUESTION_IMAGE_EXT || 'jpg'

export const US = 'us'
export const UK = 'uk'
export const CA = 'ca'
export const AU = 'au'

export const ROUTES = {
  HOME: '/',
  QUESTIONS: '/questions',
  RESULTS: '/results',
}

export const TYPE_ANSWER_CLICK = 'TYPE_ANSWER_CLICK'
export const TYPE_RESET = 'TYPE_RESET'

export const UTM_STRING =
  '?utm_campaign=MC_VCA_CRTR_BoardGenerator_FY20&utm_medium=campaign&utm_source=Pinterest&utm_content=MC_VCA_CRTR_BoardGenerator_FY20'
