import { useCallback, useEffect, useReducer, useState } from 'react'
import { TYPE_ANSWER_CLICK, TYPE_RESET } from '../constants'
import useAnswerStorage from './useAnswerStorage'
import { questions as questionList } from '../data/data.json'
const selectAnswer = (
  state,
  { answerIndex, questionIndex, type, data = [] },
) => {
  switch (type) {
    case TYPE_ANSWER_CLICK:
      // clear response (or remove next line if multiple responses allowed)
      const { maxSelections } = questionList[questionIndex]
      if (maxSelections === 1) state[questionIndex] = []

      const indexExisting = state[questionIndex].findIndex(
        i => i === answerIndex,
      )

      if (indexExisting > -1) state[questionIndex].splice(indexExisting, 1)
      else state[questionIndex].push(answerIndex)

      return [...state]
    case TYPE_RESET:
      return data.map(() => [])
    default:
      throw new Error(`Unknown type: ${type}`)
  }
}

function* cartesian(head, ...tail) {
  let remainder = tail.length ? cartesian(...tail) : [[]]
  for (let r of remainder) for (let h of head) yield [h, ...r]
}

const useQuiz = data => {
  const { storedAnswers, serializeAnswers } = useAnswerStorage(data.list)
  const [boardResults, setBoardResults] = useState(null)
  const [values, setValues] = useState([])
  const [price, setPrice] = useState(false)
  const [foundNothing, setFoundNothing] = useState(false)
  const [questionId, setQuestionId] = useState(1)

  const clearBoardResults = useCallback(() => {
    setBoardResults(null)
  }, [])
  const initAnswerArrays = questionList.map(() => [])
  const [answers, setAnswer] = useReducer(
    selectAnswer,
    storedAnswers.length ? storedAnswers : initAnswerArrays,
  )

  const clearStoredAnswers = useCallback(() => {
    setAnswer({ type: TYPE_RESET, data: data.list })
    serializeAnswers()
  }, [data, serializeAnswers])

  const inResult = (vals, selection) => {
    return vals.split(',').filter(o => o.trim() === selection).length === 1
  }

  const setResult = useCallback(
    answerIndexes => {
      const { boards } = data
      const isValid = () => {
        let ans = answers.filter(arr => arr.length)
        return ans.length === 3
      }

      if (!isValid()) {
        setQuestionId(1)
        setBoardResults([])
        setFoundNothing(true)
        // todo: set question index to zero
        return
      }

      setPrice(questionList[1].answers[answers[1]].value)

      const meetsCondition = (resultValue, boardValue) => {
        return (
          resultValue === '*' ||
          resultValue === boardValue ||
          inResult(resultValue, boardValue)
        )
      }

      let results = []
      let vals = []

      for (let c of cartesian(...answerIndexes)) {
        const res = {}
        //eslint-disable-next-line
        c.map((o, i) => {
          const val = questionList[i].answers[o].value
          vals = [...vals, val]
          res[`value${i + 1}`] = val
        })

        // custom responses
        const responses = boards.filter(
          b =>
            meetsCondition(b.value1, res.value1) &&
            meetsCondition(b.value2, res.value2) &&
            meetsCondition(b.value3, res.value3),
        )
        results = [...results, ...responses]
      }
      setValues(vals)

      const distinctResults = results.filter(
        (x, xi) => !results.slice(xi + 1).some(y => y.id === x.id),
      )

      // remove empty urls
      setBoardResults(
        distinctResults.map(o => (o.url === '"' ? { ...o, url: null } : o)),
      )
    },
    //eslint-disable-next-line
    [data],
  )

  useEffect(() => {
    serializeAnswers(answers)
  }, [answers, serializeAnswers])

  return {
    answers,
    boardResults,
    clearBoardResults,
    clearStoredAnswers,
    foundNothing,
    price,
    questionId,
    setAnswer,
    setFoundNothing,
    setQuestionId,
    setResult,
    values,
  }
}

export default useQuiz
