import React, { useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { AnimatePresence } from 'framer-motion'
import Answer from '../containers/questions/Answer'
import { QuestionTransitions } from '../../style/transitions'
import styled, { css } from 'styled-components/macro'
import { rem } from 'polished'
// import { rem } from 'polished'

const Answers = ({
  answers,
  currentQuestion,
  isFlex,
  questionIndex,
  handleTransitionComplete,
  handleAnswerSelect,
}) => {
  useEffect(() => {
    handleTransitionComplete(false)
  }, [handleTransitionComplete, questionIndex])

  useEffect(() => {
    handleTransitionComplete(true)
  }, [handleTransitionComplete])

  const handleGalleryReload = useCallback(() => {
    handleTransitionComplete(true)
  }, [handleTransitionComplete])

  const { answers: AnswerList } = currentQuestion
  return (
    <AnimatePresence exitBeforeEnter onExitComplete={handleGalleryReload}>
      <AnswerContainer
        key={questionIndex}
        isFlex={isFlex}
        count={AnswerList.length}
      >
        {AnswerList.map((answer, answerIndex) => {
          const isSelected = answers[questionIndex].some(i => i === answerIndex)
          return (
            <Answer
              animate={QuestionTransitions.animate}
              backgroundImage={answer.id}
              clickHandler={handleAnswerSelect(answerIndex)}
              exit={QuestionTransitions.exit}
              initial={QuestionTransitions.initial}
              isFlex={isFlex}
              isSelected={isSelected}
              key={answer.text}
              label={answer.text}
              transition={QuestionTransitions.transition(
                0.35,
                answerIndex * 0.05,
              )}
            />
          )
        })}
      </AnswerContainer>
    </AnimatePresence>
  )
}

Answers.propTypes = {
  answers: PropTypes.array,
  currentQuestion: PropTypes.object,
  isFlex: PropTypes.bool,
  questionIndex: PropTypes.number,
  handleTransitionComplete: PropTypes.func,
  handleAnswerSelect: PropTypes.func,
}

export default Answers

const AnswerContainer = styled.div`
  ${({ theme, isFlex, count }) => css`
    position: relative;
    margin: 0 0 3em;
    flex-wrap: wrap;
    transition: display 0s 1s, flex 0s 1s, width 0s 1s;
    justify-content: center;

    ${isFlex
    ? css`
          display: flex;

          & > * {
            flex: 0 0 calc(50% - ${rem(5)});
            ${theme.media.lg`
              flex: ${count > 6 ? '0 0 25%' : '0 0 33%'};
            `}
          }
        `
    : css`
          width: 50%;
          margin: 0 auto 3em;
          ${theme.media.xs`
            margin: 0 auto 2em;
          `};
          ${theme.media.sm`
            margin: 0 auto 3em;
          `};
        `}

    ${theme.media.lg`
      margin: 2em auto 3em;
      max-width: ${count > 6 ? '86%' : '60%'};
      justify-content: center;
    `};

    .flickity-enabled:focus {
      outline: none !important;
      border: none;
    }
  `}
`
