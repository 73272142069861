import styled, { css } from 'styled-components/macro'
import { rem } from 'polished'

export const BackArrow = styled.img.attrs(({ src }) => ({
  src: require('../../assets/images/back.png'),
}))`
  ${({ theme }) => css`
    display: inline;
    width: ${rem(12)};
    height: auto;
    margin-left: ${theme.space(1.5)};

    ${theme.media.lg`
      width: 100%;
    `};
  `}
`
