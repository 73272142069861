import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components/macro'
import { rem } from 'polished'
import Text from '../../common/Text'
import { motion } from 'framer-motion'
import {
  CIRCULAR_STD_BOLD,
  HELVETICA_FOR_TARGET_MEDIUM,
} from '../../../constants/type'

const Answer = ({
  isFlex,
  isSelected,
  backgroundImage,
  clickHandler,
  label,
  ...props
}) => {
  return (
    <MotionContainer {...props}>
      <InitContainer>
        <ImageContainer
          onClick={clickHandler}
          isFlex={isFlex}
          isSelected={isSelected}
        >
          <Text
            lineHeightMultiplier={1.1}
            helv
            color="white"
            font={CIRCULAR_STD_BOLD}
            size={16}
            xl={20}
            lineHeight={1}
          >
            {label}
          </Text>
        </ImageContainer>
      </InitContainer>
    </MotionContainer>
  )
}

Answer.propTypes = {
  isSelected: PropTypes.bool,
  isFlex: PropTypes.bool,
  backgroundImage: PropTypes.string.isRequired,
  clickHandler: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
}

export default Answer

const MotionContainer = styled(motion.div)``

const InitContainer = styled.div`
  margin-top: ${rem(10)};
  user-select: none;
`

const ImageContainer = styled(motion.div)`
  ${({ theme, isFlex, isSelected }) => css`

    user-select: none;
    height: 3.5em;
    width: ${isFlex ? 'calc(100% - 1em)' : '100%'};
    background-color: ${theme.color.mainFont};
    position: relative;
    border-radius: 50px;
    padding: 0.3em 0;
    font-size: .8em;
    margin: 0 auto;
    cursor: pointer;
    border: solid 4px #9a1718;
    ${theme.media.lg`
      height: 5em;
    `}

    ${isSelected &&
      css`
        background-color: ${theme.color.questionText};
        border: solid 4px ${theme.color.mainFont};
      `}
    
    ${Text} {
      font-family: ${HELVETICA_FOR_TARGET_MEDIUM};
      text-align: center;
      padding: 0 ${rem(5)};
      color: ${theme.color.questionText};
      position: relative;
      top: 50%;
      transform: translateY(-50%);

      ${isSelected &&
        css`
          color: ${theme.color.mainFont};
        `}
        
  `}
`
