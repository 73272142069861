import { useEffect, useState } from 'react'

export default () => {
  const [size, setSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  })

  const resizeHandler = () => {
    const { innerWidth, innerHeight } = window
    // const { lg } = breakpointsPx

    // let width = '100%'
    // let height = 'auto'
    //
    // if (innerWidth >= lg && innerHeight * 1.657 <= innerWidth) {
    //   height = `${innerHeight}px`
    //   width = `${innerHeight * 1.657}px`
    // }

    setSize({
      width: innerWidth,
      height: innerHeight,
    })
  }

  useEffect(() => {
    resizeHandler()
    window.addEventListener('resize', resizeHandler)
    return () => {
      window.removeEventListener('resize', resizeHandler)
    }
  }, [])

  return size
}
