import styled, { css } from 'styled-components/macro'
import { rem } from 'polished'

const Container = styled.div`
  ${({ theme }) => css`
    position: relative;
    height: 100%;

    h1 {
      max-width: ${rem(950)};
    }
  `}
`

export default Container
