import React from 'react'
import styled, { css } from 'styled-components/macro'
import { useAppContext } from '../../../../context/AppContext'
import { QuestionTransitions } from '../../../../style/transitions'
import { AnimatePresence, motion } from 'framer-motion'
import Container from '../../../common/Container'
import { rem } from 'polished'
import Text from '../../../common/Text'
import { ReactComponent as Loader } from '../../../../assets/images/waiting.svg'
import { fontSizes } from '../../../../style/theme'
import {
  HELVETICA_FOR_TARGET,
  HELVETICA_FOR_TARGET_BOLD,
} from '../../../../constants/type'
import Ellipses from '../../../common/Ellipses'

const ProcessingBoard = () => {
  const {
    media: { isSmall },
  } = useAppContext()
  const { pc } = fontSizes

  return (
    <MotionDiv
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.35 }}
    >
      <ProcessContainer>
        <BuckleUpContainer
          isSmall={isSmall}
          initial={QuestionTransitions.initial}
          animate={QuestionTransitions.animate}
          exit={QuestionTransitions.exit}
          transition={QuestionTransitions.transition(0.7, 0.25)}
        >
          <AnimatePresence>
            <Lead key={1}>
              <Text
                size={38}
                lg={49}
                xl={pc.title}
                center
                font={HELVETICA_FOR_TARGET_BOLD}
                lineHeightMultiplier={1.1}
              >
                Just a moment.
              </Text>
            </Lead>
            <div key={2}>
              <Description
                size={24}
                xl={33}
                center
                font={HELVETICA_FOR_TARGET}
                lineHeightMultiplier={1.2}
              >
                Holiday magic at work
                <Ellipses />
              </Description>
            </div>
            <SpinContainer>
              <LoadingSpinner />
            </SpinContainer>
          </AnimatePresence>
        </BuckleUpContainer>
      </ProcessContainer>
    </MotionDiv>
  )
}

ProcessingBoard.propTypes = {}

export default ProcessingBoard

const ProcessContainer = styled(Container)`
  ${({ theme }) => css`
    ${theme.media.lg`
    `}
  `}
`

const MotionDiv = styled(motion.div)`
  ${({ theme }) => css`
    ${theme.media.lg`
      opacity: 1;
      position: relative
    
    `}
  `}
`
const SpinContainer = styled.div`
  ${({ theme }) => css`
    width: 30%;
    max-width: ${rem(144)};
    margin-bottom: 1em;

    ${theme.media.lg`
      margin-bottom: 2em;
    `}
  `}
`
const LoadingSpinner = styled(Loader)`
  width: 100%;
  height: auto;
  animation: rotation 2s infinite linear;

  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
`
const BuckleUpContainer = styled(motion.div)`
  ${({ theme, isSmall }) => css`
    position: ${isSmall ? 'relative' : 'absolute'};
    left: 0;
    right: 0;
    top: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-content: center;
    align-items: center;

    img {
      width: 70%;
      height: 70%;
      object-fit: contain;
    }

    ${Text} {
      text-align: center;
      margin-bottom: 0;

      ${theme.media.lg`


      `}
    }

    ${theme.media.lg`
      width: 100%;
      padding-top: 0;
      position: relative;
      img {
        width: ${rem(370)}};
        height: auto;
      }
    `};

    ${theme.media.xl`
      img {
        height: auto;
      }
    
    `};
  `}
`

const Lead = styled(Text)`
  ${({ theme }) => css`
    margin: 6% auto;

    ${theme.media.lg`
      margin: 0 auto;
    `}
  `}
`
const Description = styled(Text)`
  ${({ theme }) => css`
    margin: 0 auto 2em !important;
    ${theme.media.lg`
      margin: 0.2em auto 2em !important;
    `}
  `}
`
