import styled, { css } from 'styled-components/macro'
import { motion } from 'framer-motion'

const PreviousLink = styled(motion.div)`
  ${({ theme, results }) => css`
    cursor: pointer;
    display: flex;
    align-items: center;
    margin: 2.1em 0 0 -0.5em;

    div {
      transform: translateY(3px);
    }

    opacity: 1;
    transform: none;
    position: absolute;
    z-index: 2;

    ${theme.media.lg`
      width: 1.39%;
      transform: none;
      top: 0;
      position: fixed;
      margin-top: 140px;
    `}
  `};
`

export default PreviousLink
