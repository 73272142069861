import styled, { css } from 'styled-components/macro'

const SmallBreak = styled.br`
  ${({ theme }) => css`
    ${theme.media.lg`
      display: none;
    `}
  `}
`
export default SmallBreak
