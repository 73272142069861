import { em, stripUnit, rem } from 'polished'
import { css } from 'styled-components/macro'
import { mapValues, get } from 'lodash'
import Bowser from 'bowser'

export let device = { mobile: false }
export let isMobile = false
if (typeof window !== 'undefined') {
  device = Bowser.getParser(window.navigator.userAgent)
  const type = get(device, 'parsedResult.platform.type')
  isMobile = device.mobile = type === 'mobile' || type === 'tablet'
}

const THE_FOLD = 900

// px
export const breakpointsPx = {
  xs: 0,
  sm: 321,
  md: 475,
  lg: 769,
  xl: 1025,
  xxl: 1400,
}

// em
export const breakpoints = mapValues(breakpointsPx, value =>
  stripUnit(em(value)),
)
export const color = {
  mainFont: '#7F0613',
  background: '#800613',
  backgroundAlt: '#a40035',

  nextQuestionButton: '#efd0c0',
  questionText: '#efd0c0',
  answerBackground: '#e2ba3a',
  headerBackground: '#cc0000',

  darkGray: '#3e4744',
  lightBlue: '#00b5d0',
  lightGrey: '#c7c7c7',
  lightRed: '#f3b7aa',
  navy: '#1f396e',
  darkBlue: '#08285f',
  darkRed: '#e83195',
  pink: '#f8c9d5',
  mediumPink: '#f7b5c5',
  darkPink: '#df068c',
  teal: '#61bfc9',
  turquoise: '#3fbfd6',
  darkGreen: '#275626',
  darkFont: '#605f5e',
  white: '#fff',
}

// px
export const fontSizes = {
  xs: 17, // h6
  sm: 17, // h5
  md: 24, // p
  lg: 38, // h4
  xl: 34, // h3
  xxl: 44, // h2
  xxxl: 54, // h1
  pc: {
    sm: 17,
    title: 54,
    desc: 30,
  },
}

const linkHoverStyle = css`
  position: relative;
  text-decoration: none;
  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    height: 2px;
    bottom: -2px;
    background-color: white;
    transition: opacity 0.4s;
    opacity: 1;
  }
  &:hover {
    &:after {
      opacity: 0.25;
    }
  }
`

const spacingBase = 10
export const space = (multipier = 2) => rem(spacingBase * multipier)

export const layout = {
  spacingBase,
  linkHoverStyle,
}

export const media = Object.keys(breakpoints).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (min-width: ${breakpoints[label]}em) {
      ${css(...args)};
    }
  `
  return acc
}, {})

export const mediaAndBelowFold = Object.keys(breakpoints).reduce(
  (acc, label) => {
    acc[label] = (...args) => css`
      @media (min-width: ${breakpoints[label]}em) and (max-height: ${rem(
  THE_FOLD,
)}) {
        ${css(...args)};
      }
    `
    return acc
  },
  {},
)

export const belowFold = (...args) => css`
  @media (max-height: ${rem(THE_FOLD)}) {
    ${css(...args)};
  }
`

const theme = {
  breakpoints,
  breakpointsPx,
  color,
  device,
  fontSizes,
  layout,
  media,
  mediaAndBelowFold,
  belowFold,
  space,
}

export default theme
