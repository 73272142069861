import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components/macro'
import Text from './Text'
import { Link } from '@reach/router'
import { rem } from 'polished'
import theme, { media } from '../../style/theme'
import {
  CIRCULAR_STD_BOLD,
  HELVETICA_FOR_TARGET_BOLD,
} from '../../constants/type'

const BLUE = 'blue'
const TRANSPARENT = 'transparent'
const WHITE = 'white'
const themeKeys = [BLUE, TRANSPARENT, WHITE]

const Button = ({
  center,
  children,
  color,
  disabled,
  href,
  minWidth,
  onClick,
  to,
  text,
  rel,
  small,
  target,
  type,
  uppercase,
  fontFamily,
  ...props
}) => {
  const Wrapper = to ? StyledLink : StyledWrapper

  const content = (
    <Container minWidth={minWidth}>
      <Text
        center
        sans
        size={16}
        xl={23}
        color={text || disabled ? theme.color.mainFont : theme.color.pink}
        uppercase={uppercase}
        font={fontFamily || HELVETICA_FOR_TARGET_BOLD}
      >
        {children}
      </Text>
    </Container>
  )

  return !!href ? (
    <StyledExternalLink href={href} target={target} rel={rel} type={type}>
      {content}
    </StyledExternalLink>
  ) : (
    <Wrapper
      to={to}
      onClick={onClick}
      disabled={disabled}
      color={color}
      center={center}
      type={type}
      text={text}
    >
      {content}
    </Wrapper>
  )
}

Button.propTypes = {
  center: PropTypes.bool,
  children: PropTypes.any,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  fontFamily: PropTypes.string,
  href: PropTypes.string,
  small: PropTypes.bool,
  rel: PropTypes.string,
  to: PropTypes.string,
  target: PropTypes.string,
  type: PropTypes.string,
  minWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  text: PropTypes.string,
  theme: PropTypes.oneOf(themeKeys),
  uppercase: PropTypes.bool,
}

Button.defaultProps = {
  padHorizontal: rem(23),
  theme: BLUE,
  uppercase: false,
  color: theme.color.mainFont,
  fontFamily: HELVETICA_FOR_TARGET_BOLD,
}

export default Button

const wrapperStyle = ({ disabled, center, type, color, theme, text }) => css`
  display: inline-flex;
  justify-content: center;
  transition: opacity 0.3s;
  background-color: ${color};
  cursor: pointer;
  border: solid 4px ${color};
  font-family: ${HELVETICA_FOR_TARGET_BOLD};
  font-size: 1.3em;
  border-radius: 50px;
  padding: 0 2.1em;

  ${center &&
    css`
      margin-left: 50%;
      transform: translateX(-50%);
    `}

  ${type === WHITE &&
    css`
      background-color: #fff;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      margin-bottom: 1em;
      max-width: 37%;
      border: 0;
      box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.19);
      font-family: ${CIRCULAR_STD_BOLD};
    `};

  ${type === TRANSPARENT &&
    css`
      background-color: transparent;
    `};

  ${disabled &&
    css`
      background-color: #de6860;
      pointer-events: none;
      color: #c9423f;
      border-color: #a6030a;
      div {
        color: #a6030a;
      }
    `}

  ${text &&
    css`
      color: ${text};
      border-color: ${text};
    `}
`

const StyledWrapper = styled.button`
  ${wrapperStyle};

  ${({ theme }) => css`
    ${theme.media.xs`
      padding: 0 1.2em;
    `}
    ${theme.media.sm`
      padding: 0 2.1em;
    `}
    ${theme.media.xl`
      height: auto;
      width: calc(33% - 1em);
      margin: 0 auto;
      position: relative;
      display: block;
      transform: none;
    `}
  `}
`

const StyledLink = styled(Link)`
  ${wrapperStyle};
`

const StyledExternalLink = styled.a`
  ${wrapperStyle};
`

const Container = styled.div`
  ${({ theme, minWidth, center }) => css`
    padding: ${theme.space(0.9)} ${theme.space(1)};
    display: flex;
    align-items: center;
    justify-content: center;

    ${Text} {
      vertical-align: top;
      line-height: inherit;

      ${theme.media.sm`
        white-space: nowrap;
      `};
    }

    img {
      width: 20px;
      height: auto;
      transform: translateY(-3px);
    }

    ${center &&
      css`
        margin: 0 auto;
      `}
    ${typeof minWidth === 'object' &&
      css`
        ${Object.keys(minWidth).map(
          key => css`
            ${media[key]`
            min-width: ${minWidth[key]}
          `}
          `,
        )}
      `}
  `}
`
