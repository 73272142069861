import { useCallback } from 'react'

function useLocalStorage(key, initialValue) {
  const setValue = useCallback(
    value => {
      if (!value) {
        window.localStorage.removeItem(key)
      } else {
        window.localStorage.setItem(key, value)
      }
    },
    [key],
  )
  const item = window.localStorage.getItem(key)
  if (!item && initialValue) setValue(initialValue)

  return [item || initialValue, setValue]
}

export default useLocalStorage
